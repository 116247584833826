export const educationData = [
    {
        id: 1,
        institution: 'Kailali Model Colleage',
        course: 'High School',
        startYear: '2014',
        endYear: '2016'
    },
    {
        id: 2,
        institution: 'NAST',
        course: 'Bacholer in computer application',
        startYear: '2016',
        endYear: '2022'
    }
]